/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */


body {
  color: black;
}

#header {
  padding: 0em 0 0 0;
}

#header h1 {
  padding: 1em 0 1em 0;
}

h1 .textSubtitle {
  font-weight: bold;
  font-size: larger;
  letter-spacing: .2em;
}

@include breakpoint('<=narrower') {
  #titleBar .toggle:before {
    color: black;
  }
  #titleBar .title {
    background: white;
    color: black;
    box-shadow: 0 4px 0 0 DarkRed;
  }
  .textSubtitle {
    display: none;
    visibility: hidden;
  }
}

table.featuretable th {
  background: darkred;
  color: white;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
}

table.featuretable, table.featuretable th, table.featuretable td {
  border: 1px solid black;
  padding: .25em 1em .25em 1em;
  vertical-align: middle;
  text-align: center;
}

table.featuretable tr:nth-child(even) {
  background: white;
}

table.featuretable tr:nth-child(odd) {
  background: lightgray;
}

table.featuretable td:first-child {
  font-weight: bold;
}

.image.featured.articles img {
  display: none;
  visibility: hidden;
}

.image.featured.news img, .image.featured.publications img {
  width: 25%;
}

div.pullquote {
  background: DarkRed;
  color: white;
  padding: 1em;
  margin-top: 3em;
  height: 100%
}

div.pullquote a.button.quotebutton {
  background: #e0e0e0;
  color: darkred;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div.pullquote a.quotebutton:hover {
  color: black !important;
  font-weight: bold;
}

@include breakpoint('<=narrower') {
  div.featuretablecontainer {
    overflow-x: auto;
  }
  table.featuretable {
    font-size: smaller;
    padding: 1px;
  }
}

div.newsitem h3 {
  margin-bottom: 5px;
}

div.newsitem time div, .timediv {
  margin-bottom: 2px;
}

div.newsitem time, .timediv {
  font-weight: bold;
  margin-bottom: 5px;
}

header.major {
  margin: 0;
  padding: 0;
}

header.major p {
  line-height: 130% !important;
  letter-spacing: .1rem !important;
  border-top: 1px solid #c0c0c0;
  top: 0em;
  font-weight: 300;
}

ul.tags {
  margin-left: 0;
  @include breakpoint('<=narrow') {
    display: block;
  }
}

li.tagvalue a:hover {
  background: #eee !important;
  color: black;
}

p {
  margin: 0 0 .5em 0 !important;
}

h2 {
  margin-top: .25em !important;
  margin-bottom: .1em !important;
}

h3 {
  margin-top: .2em !important;
  margin-bottom: .1em !important;
}

h2.title {
  margin-bottom: 0 !important;
  line-height: 1em !important;
}

header p.description {
  margin-top: .5em !important;
}

p.categorydescription {
  font-size: 1.25em;
  padding-bottom: .5em !important;
  margin-top: -.5em !important;
}

h2.category {
  font-size: 1.75em !important;
  font-weight: 600 !important;
  padding-bottom: .25em !important;
}

h2.category .categoryname {
  font-size: 1.0em !important;
  xfont-weight: normal !important;
}

p.author {
  font-weight: bold;
  font-style: italic;
}


img.max-100-pct {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 100%;
  height: auto;
}

img.max-80-pct {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 80%;
  height: auto;
}

img.max-50-pct {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 50%;
  height: auto;
}

img.max-100-pct-centered {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}


img.max-80-pct-centered {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 80% !important;
  height: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}

img.max-50-pct-centered {
  padding-top: .5em;
  padding-bottom: .4em;
  max-width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

p.max-80-pct-left-caption {
  max-width: 80% !important;
  height: auto;
  display: block !important;
}

p.max-80-pct-centered-caption {
  max-width: 80% !important;
  height: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  text-align: center !important;
  padding-bottom: 1.2em !important;
}

p.centered-quote {
  color: #404040;
  background: #eaeaea;
  font-size: larger;
  font-weight: bold;
  margin-left: 3em !important;
  margin-right: 4em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  padding-top: .6em !important;
  padding-bottom: .6em !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-radius: 7px;
}

@include breakpoint('>narrower') {
  div.publications-body {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.separator1 {
  height: 4px;
  xbackground-image: radial-gradient(
                  closest-side,
                  hsla(0, 0%, 50%, 1.0),
                  hsla(0, 0%, 50%, 0) 100%);
  background-image: radial-gradient(
                  closest-side,
                  darkred,
                  hsla(0, 0%, 50%, 0) 90%);;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 2em;
  margin-bottom: 2em;
}

img {
  max-width: 100%;
  height: auto;
}

a.inline-button {
  font-family: arial, helvetica, san-serif !important;
  font-weight: bold;
  font-size: 75%;
  letter-spacing: 1px;
  padding: 3px 4px 3px 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #20538D;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  background: darkred;
  background: #ccc;
  border: 1px solid black;
  color: #000;
  text-decoration: none;
}

a.inline-button:hover {
  color: white;
  text-decoration: none;
  background: #808080;
}

ul > li > ul {
  margin-bottom: 0 !important;
}

p.callout {
  border: 1px solid #417bfd;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #edf1ff;
}

p.callout-centered {
  border: 1px solid #417bfd;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #edf1ff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

div.narrow-80 {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

p.callout-centered-gray {
  width: 100%;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #dcdcdc;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

img.fadeout {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
  xmask-image: radial-gradient(ellipse 90% 80% at 48% 78%, black 40%, transparent 50%);
  xmask-image: radial-gradient(circle at 10% 10%, transparent 0%, black 100%);
}

table.graybar tbody td {
  padding: 3px 10px 3px 10px;
  border: 1px solid black;
}

table.graybar tbody tr:nth-child(odd) {
  background-color: #dcdcdc;
}
